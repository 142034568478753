import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, effect, signal } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {api} from '../../../../environments/api_url';
import { FlutterService } from '../flutter/flutter.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private role_id: string = '';
  private token: string = '';
  userLoginStatus = signal(false);
  isUserVendor = signal(false)

  isUserLogged = new BehaviorSubject<boolean>(false);
  isVendorUserLogged = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private route: Router,
    private flutter: FlutterService,

  ) { 
    if(localStorage.getItem('token')){
      this.userLoginStatus.set(true)
      localStorage.getItem('roleId') ==='2' ?  this.isUserVendor.set(true) : this.isUserVendor.set(false)
    } else {
      this.userLoginStatus.set(false)
      this.isUserVendor.set(false)
    }
  }

  userLogin(userEmail: string, userPassword: string): Observable<any> {
    const url = api.URL + '/api/login';
    const payload = {
      email: userEmail,
      password: userPassword
    };

    let dataPayload = this.http.post(
      url,
      payload,
      { observe: 'response' }
    )

    dataPayload.subscribe(async (response: any) => {
      // console.log('1', response);
      const apiResponse = response.body;
      // console.log(apiResponse);

      if (apiResponse.status_code === 200) {




        // console.log( this.fileService.triggerFunction("dealerCode", "path", "filename"));
        await this.flutter.triggerFunction(apiResponse);
        
        this.userLoginStatus.set(true)
        this.token = apiResponse?.result?.token;
        const fullName = apiResponse?.result?.full_name;
        const email = apiResponse?.result?.email;
        const roleId = apiResponse?.result?.role_id;
        const role = apiResponse?.result?.role;
        const hashid = apiResponse?.result?.hash_id;
        const bannerMessage = apiResponse?.result?.banner_message;
        const passwordUpdateRequired = apiResponse?.result?.password_update_required;
        const vendor_type = apiResponse?.result?.vendor_type;

        localStorage.setItem('token', this.token);
        localStorage.setItem('fullName', fullName);
        localStorage.setItem('email', email);
        localStorage.setItem('roleId', roleId);
        localStorage.setItem('role', role);
        localStorage.setItem('userData', JSON.stringify(apiResponse));
        localStorage.setItem('hashid', hashid);
        localStorage.setItem('vendorType', vendor_type)

        if(bannerMessage){
          localStorage.setItem('bannerMessage', bannerMessage);
        }
        if(passwordUpdateRequired === 1){
          localStorage.setItem('passwordUpdateRequired', passwordUpdateRequired);
        }
        
        if (roleId === 2) {
          this.route.navigate(['merchants']);
          this.isVendorUserLogged.next(true)
          this.isUserVendor.set(true);
        } else if (roleId === 1) {
          // const navigationExtras: NavigationExtras = {
          //   queryParams: { role: roleId, token: this.token }
          // };

          this.route.navigate(['admin'] /*, navigationExtras */);
          this.isUserLogged.next(true);
          this.isUserVendor.set(false);
        } else {

        }
      }
    }
      // ,(error) => {
      //   console.error('Error while calling API:', error);
      // }
    )


    return dataPayload;
  }

  


  resetPassword(email: string) {
    const url = api.URL + '/api/password_reset';
    const payload = {
      email: email
    }
    let dataPayload = this.http.post(
      url,
      payload,
      { observe: 'response' }
    )
    dataPayload.subscribe((result) => {
      console.log(result);
    })

    return dataPayload
  }

  fcmNotification(payload: any, token: string) {
    const url = api.URL + '/api/fcm-token';
    let dataPayload = this.http.post(
      url,
      payload,
      { observe: 'response', headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }) }
    )
    dataPayload.subscribe((result) => {
      console.log(result);
    })

    return dataPayload
  }


  userLogout() {
    this.userLoginStatus.set(false);
    const url = api.URL + '/api/logout';
    return this.http.post(url, '')
  }





  getToken() {
    return localStorage.getItem('token');
  }


  getLoginStats() {
    return this.isUserLogged.asObservable()
  }
}
